import React, { useEffect, useState } from "react";
import { Container, Navbar } from "@components";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import Image from "next/image";
import { sanityIoImageLoader } from "@core/utils";
import Head from "next/head";

const Header = dynamic(() => import("../src/components/header").then((mod) => mod.Header), {
    ssr: false,
});

const NotFound: React.FC = () => {
    const [text, setText] = useState("Go Back");
    const router = useRouter();
    const [userType, setUserType] = useState(null);

    useEffect(() => {
        setText((window.history.state && window.history.state.idx > 0) ? "Go Back" : "Go Home");
        setUserType(JSON.parse(localStorage.getItem("profile"))?.is_business)
    }, [router.isReady, router.asPath])

    const handleAction = () => {
        if (window.history.state && window.history.state.idx > 0) {
            router.back();
        } else {
            router.push("/");
        }
    }

    return (
        <Container>
            <Head>
                <title>404 - Page Not Found</title>
                <meta name="description" content="Oops! Looks like you've stumbled upon a missing page. Navigate back and continue exploring our site." />
                <meta name="robots" content="noindex" />
            </Head>

            <Header />

            <section className="flex justify-center items-center">
                <div className="flex flex-col justify-center items-center gap-4 mx-auto max-w-[1240px] px-2 mt-28 md:mt-43 pb-24">
                    <Image 
                        src='https://jhattse.com/api/v1/file/?key=super/01F2NJUR_404.png'
                        alt='404'
                        width={300}
                        height={150}
                        loader={sanityIoImageLoader}
                        className="object-cover"
                    />
                    <h1 className="text-lg text-neutral-800 font-manrope font-semibold">
                        Oops! Page not Found
                    </h1>
                </div>
            </section>
            <Navbar/>
        </Container>
    );
};

export default NotFound;